import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import PagesList from '../components/slides/learn/PagesList';

const Learn = () => {
  return (
    <Layout>
      <SEO
        title="Learn | Deadline Funnel"
        description="Learn how to use Deadline Funnel to level up your email marketing and grow your business."
      />
      <PagesList />
    </Layout>
  )
};

export default Learn;