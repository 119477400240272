import React, { Fragment } from 'react';

import PagesListHeader from './PagesListHeader';
import PagesListContent from './PagesListContent';

const PagesList = () => {

  return (
    <Fragment>
      <PagesListHeader />
      <PagesListContent />
    </Fragment>
  );
};

export default PagesList;